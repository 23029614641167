/** @jsx jsx */
import { css } from "@emotion/react";

export const politicaPage = css`
  position: relative;
  padding-top: 210px;
  padding-bottom: 120px;
  background: #4BE66A;
  font-family: "normalidad-variable", sans-serif;
  font-variation-settings: "wght" 700;
  h2 {
    color: #33535E;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  h3 {
    color: #33535E;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    margin-bottom: 16px;
    color: #33535E;
    font-family: 'Montserrat', sans-serif;
  }
`

export const contentPage = css`
  padding-top: 210px;
  padding-bottom: 120px;
  background: #4BE66A;
  font-variation-settings: "wght" 600;
  h2 {
    color: #312E2D;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-size: 60px;
    font-style: normal;
    line-height: 120%;
    font-family: "normalidad-variable", sans-serif;
  }
  p {
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    margin-bottom: 16px;
    color: #312E2D;
    font-family: 'Montserrat', sans-serif;
  }
  .content-mensage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .content-sucess {
    text-align: center;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      width: 100%;
    }
  }
`
